<template>
  <v-container
    id="regular-tables-view"
    fluid
    tag="section"
  >
    <material-card
      icon="mdi-clipboard-text"
      icon-small
      title="Testing Report"
      color="accent"
    >
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="date.dateFrom"
              type="date"
              label="Date From"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="date.dateTo"
              type="date"
              label="Date To"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="displayMethod"
              :items="displayItems"
              label="Display Method"
            />
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-btn
              block
              color="accent"
              class="mt-2"
              @click="loadReportData"
            >
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <stacked-bar-chart
              :chart-data="testerReportStackData"
              :options="stackedOptions"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <linc-chart
              :chart-data="createdQtyLineData"
              :options="stackedOptions"
            />
          </v-col>
          <v-col cols="12">
            <v-data-table
              :items="testUserDetailData"
              :headers="testUserDetailHeader"
              :search="searchTestTable"
              dense
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <v-toolbar-title>Detail Report</v-toolbar-title>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  ></v-divider>
                  <v-spacer></v-spacer>
                  <v-col
                    cols="6"
                  >
                    <v-text-field
                      v-model="searchTestTable"
                      label="Search (Search  User pr PN)"
                      class="mx-4 mt-5"
                    />
                  </v-col>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="(itemTypeReport, key) in summaryTestReportItemType"
            :key="key"
            cols="12"
            md="6"
          >
            <v-data-table
              :items="itemTypeReport.test_data"
              :headers="summaryTestReportItemHeader"
              dense
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                >
                  <v-toolbar-title>Test  Report By Type {{itemTypeReport.date}}</v-toolbar-title>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  ></v-divider>
                </v-toolbar>
              </template>
              <template v-slot:item.test_rate="{ item }">
                {{ decimalToPercentage(item.test_rate) }} %
              </template>
              <template v-slot:item.failed_rate="{ item }">
                {{ decimalToPercentage(item.failed_rate) }} %
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </material-card>
  </v-container>
</template>

<script>
  import dashboardApi from '@/api/dashboard-statistics'
  import chartDataHandle from '@/util/chart-data-handle'
  import userApi from '@/api/user'
  import StackedBarChart from '@/util/charts/stacked-bar'
  import LincChart from '@/util/charts/line'
  import moment from 'moment'
  export default {
    name: 'TestingReport',
    components: { StackedBarChart, LincChart },
    data () {
      return {
        date: {
          dateFrom: '',
          dateTo: '',
        },
        displayMethod: 'Month',
        displayItems: ['Month', 'Day'],
        basicLineChart: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{ stacked: true }],
            yAxes: [{ stacked: true }],
          },
          title: {
            display: true,
            text: 'The Daily/Month Tester Report',
          },
        },
        stackedOptions: {
          scales: {
            xAxes: [{ stacked: true }],
            yAxes: [{ stacked: true }],
          },
          responsive: true,
          maintainAspectRatio: false,
        },
        basicLineOptions: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: 'The Daily/Month Created Items Qty',
          },
        },
        dateLabel: [],
        userList: [],
        testerReportStackData: {
          labels: null,
          dateLabel: null,
        },
        createdQtyLineData: {
          labels: null,
          dateLabel: null,
        },
        testUserDetailHeader: [
          {
            text: 'ID',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: 'name', value: 'user.name', sortable: true },
          { text: 'Item', value: 'item_receipt_item.item_part_number' },
          { text: 'Label Show', value: 'item_receipt_item.label_show_quantity' },
          { text: 'Test', value: 'tested_quantity' },
          { text: 'Failed', value: 'failed_in_testing' },
          { text: 'Tested On', value: 'test_server' },
          { text: 'Created', value: 'created_at' },
        ],
        testUserDetailData: [],
        searchTestTable: '',
        summaryTestReportItemHeader: [
          {
            text: 'type',
            align: 'start',
            sortable: false,
            value: 'type',
          },
          { text: 'Received', value: 'received' },
          { text: 'No Test', value: 'no_tested' },
          { text: 'Tested', value: 'tested' },
          { text: 'Failed', value: 'failed' },
          { text: 'Tested/Rec', value: 'test_rate' },
          { text: 'Failed/Rec', value: 'failed_rate' },
        ],
        summaryTestReportItemType: [],
      }
    },
    created () {
      this.loadUser()
    },
    methods: {
      loadUser () {
        userApi.index().then(response => {
          this.userList = response.data.data
          this.loadReportData()
        }).catch(error => {
          console.log(error)
        })
      },
      loadReportData () {
        dashboardApi.customTestReport(this.date).then(response => {
          this.date.dateFrom = response.data.data.dateSelected.dateFrom
          this.date.dateTo = response.data.data.dateSelected.dateTo
          this.dateLabel = chartDataHandle.dateListGenerate(this.displayMethod, this.date.dateFrom, this.date.dateTo)
          this.testUserDetailData = this.updateUserTestTableDataDate(response.data.data.tester_report_data)
          this.setTesterReportData(response.data.data.testerReportData)
          this.setDailyCreateItemData(response.data.data.createdReportData)
          this.loadSummaryTestingResultItemType(this.date)
        }).catch(error => {
          console.log(error)
        })
      },
      setTesterReportData (data) {
        console.log(data)
        const datasets = []
        if (this.displayMethod === 'Month') {
          data = chartDataHandle.orderDateToMY(data, 'date')
        }
        const testerData = {}
        for (const index in this.userList) {
          const id = this.userList[index].id
          const name = this.userList[index].name
          const testerName = name + '_ID: ' + id
          const searchResult = data.filter(object => object.user_id === id)
          if (searchResult.length > 0) {
            testerData[testerName] = []
            for (const labelDate of this.dateLabel) {
              const dateSearchResult = searchResult.filter(object => object.date === labelDate)
              if (dateSearchResult.length > 0) {
                let totalTest = 0
                for (const dateSearchResultIndex in dateSearchResult) {
                  totalTest = totalTest + parseInt(dateSearchResult[dateSearchResultIndex].testedQuantity, 10)
                }
                testerData[testerName].push(totalTest)
              } else {
                testerData[testerName].push(0)
              }
            }
            datasets.push({ label: testerName, data: testerData[testerName], backgroundColor: chartDataHandle.randomTransparentColor() })
          }
        }
        this.testerReportStackData = {
          labels: this.dateLabel, datasets,
        }
      },
      setDailyCreateItemData (data) {
        const labelName = this.displayMethod + ' Created'
        if (this.displayMethod === 'Month') {
          data = chartDataHandle.orderDateToMY(data, 'receiptDate')
        }
        const datasets = [{ data: [], label: labelName, borderColor: '#3e95cd' }]
        for (const labelDate of this.dateLabel) {
          const searchResult = data.find(object => object.receiptDate === labelDate)
          if (!searchResult) {
            datasets[0].data.push(0)
          } else {
            const number = parseInt(searchResult.totalCreated, 10)
            datasets[0].data.push(number)
          }
        }
        this.createdQtyLineData = {
          labels: this.dateLabel, datasets,
        }
        console.log(this.createdQtyLineData)
      },
      updateUserTestTableDataDate (data) {
        // eslint-disable-next-line no-return-assign
        data.forEach((element) => {
          element.created_at = moment.utc(element.created_at).local().format('YYYY-MMM-DD HH:ss')
        })
        return data
      },
      loadSummaryTestingResultItemType (date) {
        dashboardApi.summaryItemTypeTestReport(date).then(response => {
          if (response.data.code === 200) {
            this.summaryTestReportItemType = response.data.data
          }
        }).catch(() => {})
      },
      decimalToPercentage (number) {
        return (number * 100).toFixed(2)
      },
    },
  }
</script>

<style scoped>

</style>
